@tailwind base;
@tailwind components;
@tailwind utilities;
@media print {
  @page {
    size: A4;
    margin: 0;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  
  .print\\:hidden {
    display: none !important;
  }
  
  /* Ensure proper rendering of backgrounds and colors */
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

/* Hide scrollbar when printing */
@media print {
  body::-webkit-scrollbar {
    display: none;
  }
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
